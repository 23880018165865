.add-experiment {
  height: 100%;
  position: relative;
}

.add-experiment .image-input .icon {
  cursor: pointer;
}
.add-experiment .question {
  font-size: large;
  font-weight: 500;
  text-align: left;
  padding: 10px;
  width: 100%;
}

.add-experiment .experiment {
  padding: 10px;
}

.add-experiment .loader {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.add-experiment.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.add-experiment .experiment .image-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

.add-experiment .experiment .image-input button {
  position: relative;
  width: 200px;
  padding: 0;
}

.add-experiment .experiment .image-input input {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;

  cursor: pointer;
  opacity: 0;
}

.add-experiment .experiment .icon {
  margin: 0 10px;
}

.add-experiment .post-btn button {
  padding: 10px;
  margin: 10px;
  border: 0px;
  width: 80px;
  border-radius: 8px;
  color: #fff;
  background-color: #534f8f;
}
.add-experiment .post-btn button:hover {
  box-shadow: 100px;
  filter: brightness(80%);
}
