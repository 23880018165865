.modal-body-frame-picker{
    /* width: 300px !important; */
    width: 100%;
}
.modal-body-frame-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.left-button-flex{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.filename-edit{
    font-size: 12px;
}