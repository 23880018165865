.edit-series .cta {
  display: flex;
  width: 100%;
  padding: 0px;
  grid-gap: 10px;
  height: auto;
  /* align-items: center; */
  flex-direction: row;
  justify-content: space-between;
}
.edit-series .cta.form-group div{
  flex: 1 !important;
}
.edit-series .cta div button{
    width: 100%;
    padding: 0px;
    height: 30px;
  }

.edit-series .cta .title-icon input {
  visibility: hidden;
}
.formgroup-modal-flex{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
}
.formgroup-modal-flex .form-group{
  flex: 1;
}
.edit-series .image-preview {
  margin: 1px 14px 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.title-image-preview{
  height: 30px;
}
.image-preview img{
  /* height: 81px !important; */
  width: 50px !important;
  border-radius: 4px;
  margin-top: 10px !important;
  aspect-ratio: 9/16 !important;
  border: 1px solid rgb(226, 226, 226);
}
.cta-update-series{
  background: var(--sidebar_color) !important;
  border: none !important;
  outline: none;
  color: white !important;
  text-transform: capitalize !important;
}
.cta-update-series-cancel{
  border:1px solid var(--sidebar_color) !important;
  outline: none;
  color: var(--sidebar_color) !important;
  text-transform: capitalize !important;
}
.edit-series .image-preview label{
  font-size: 14px;
  font-weight: 500;
  padding: 1px 12px;
}
.edit-series .form-check-input{
  margin-top: 5px;
}

.edit-series .image-preview .icon{
    cursor: pointer;
}
.edit-series-assets{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

