.video-image {
  width: 4rem;
  height: unset;
  aspect-ratio: 10/16;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid rgba(164, 164, 164, 0.763);
}
.trailer-image{
  width: 3rem;
  height: unset;
  aspect-ratio: 10/16;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid rgba(164, 164, 164, 0.763);
}

.video-detail {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.video-detail .hasQuiz {
  background-color: rgb(54, 108, 50);
  text-align: center;
  font-size: 14px;
  padding: 2px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  width: 60px;
}

.video-info {
  margin: 5px 10px;
}

.video-category,
.video-id {
  color: gray;
}

.video-detail .video-series a {
  text-decoration: none;
  margin-top: 4px;
  font-size: small;
  font-weight: 800;
  color: rgb(112, 112, 112);
}

.edit-video{
  cursor: pointer;
}