.notifications-module .section-wrapper-device-form{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    margin: 10px auto;
    height: 94vh;
    overflow-y: scroll;
}
.notifications-module .form-section{
    flex: 2.6;
}
.notifications-module .device-preview{
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.device-preview-back{
    max-height: 630px;
    min-height: 630px;
    height: 630px;
    max-width: 300px;
    min-width: 300px;
    width: 300px;
    position: fixed;
    background: linear-gradient(rgb(68, 68, 131), skyblue, rgb(68, 68, 131), black);
}
.device-preview-mobile-frame{
    max-height: 650px;
    min-height: 650px;
    height: 650px;
    max-width: 360px;
    min-width: 360px;
    width: 360px;
    background-size: 'contain';
    background-repeat: 'no-repeat';
    margin-left: "-10px";
    margin-top: "-10px";
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.notifications-module .layout-section{
    background-color: white;
    box-shadow: 1px 2px 5px rgba(128, 128, 128, 0.396);
    border: 1px solid #78787882;
    border-radius: 5px;
}
.notifications-module .layout-heading{
    padding-left: 10px;
    padding-top: 15px;
    font-size: 18px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}
.notifications-module .layout-form-group{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    width: 90%;
    margin: 30px auto;
    align-items: center;
}
.notifications-module .layout-label{
    flex: 1;
    font-size: 14px;
    color: gray;
    text-transform: uppercase;
}
.notifications-module .layout-field{
    flex: 2;
    display: flex;
    align-items: center;
}

.editorClassName{
    /* font-size: 12px; */
}
.layout-field-filename{
    font-size: 12px;
    color: grey;
    background: white;
    padding: 4px;
    margin: 0px;
}
.layout-field-fileinput{
    width: 250px;
}
.text-span-char-count{
    font-size: 12px;
    color: grey;
}
.layout-field-fileinput-nohide{
    width: 105px;
}
.notifications-module .layout-icons{
    color: grey;
    font-size: 12px;
    /* margin-left: 10px; */
}
.notifications-module .wrapper-icon{
    border: 1px solid #78787882;
    padding: 2px 8px;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
}
.notifications-module .wrapper-icon:hover{
    background-color: aliceblue;
}
.notifications-module .color-select-input{
    border: 1px solid #78787882;
    min-width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
}
.notifications-module .inputfeild-color{
    border: none;
    outline: none;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    background-color: none;
    margin-right: 10px;
}
.notifications-module .text-inputfeild-color{
    border: none;
    outline: none !important;
}
.notifications-module .layout-editor-wrapper{
    position: relative;
    width: 100%;
    height: auto;
}
.notifications-module .react-quill-override{
    height: 150px;
}
.notifications-module .icons-emoji{
    position: absolute;
    right: 5px;
    font-size: 30px;
    top: 5px;
    filter:grayscale(1);
}
.notifications-module .emoji-picker-absolute{
    position: absolute;
    height: 150px !important;
    right: 0px;
    z-index: 100000;
    top: 40px;
}
.notifications-module .textfield-cta{
    font-size: 14px;
    border: 1px solid #78787882;
    width: 100%;
    border-radius: 4px;
    height: 40px;
    padding-left: 8px;
    text-transform: capitalize;
}
.notifications-module .mobile-frame{
    border: 1px solid #78787882;
    border-radius: 5px;
    width: cover;
}

.notifications-module .device-preview-notification-card{
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: flex-start; */
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgb(222, 222, 222);
    border: 1px solid #d4d4d4cd;
}
.device-preview-notification-card-parent-layer{
    padding-top: 5px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: white;
    width: 275px;
    height: auto;
    border: 1px solid #d4d4d4cd;
    margin-left: 23px;
    margin-top: 60px;
    margin-right: 25px;
    border-radius: 8px;
}
.deivce-preview-card-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 8px;
}
.device-preview-card-flex{
    grid-gap: 3px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.device-preview-icon-drop{
    border: 1px solid grey;
    border-radius: 8px;
    width: 18px;
    height: 13px;
    display: flex;
    justify-content: center;
    color: grey;
    cursor: pointer;
}
.notifications-module .device-preview-notification-card-context{
    min-width: 72%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.device-preview-notification-card-image{
    flex: 1;
    min-width: 28%;
}
.wrapperClassName{
    max-width: 600px;
}
.notification-thumbnail{
    width: 60px;
    height: 90px;
    border-radius: 5px;
}
.frame-top-connection{
    position: absolute;
    top: 25px;
    right: 70px;
    font-size: 8px;
    grid-gap: 4px;
    display: flex;
    color: white;
}


.notification-title p{
    margin-bottom: 0px;
    word-wrap: normal;
    overflow: hidden;
    line-height: 1.2;
    font-size: 11px;
    font-weight: bold;
}
.notification-description p{
    margin-bottom: 0px;
    word-wrap: normal;
    overflow: hidden;
    line-height: 1.2;
    font-size: 10px;
}
.notification-title{
    word-wrap: normal;
    word-break: keep-all;
}
.notification-button{
    border: none;
    background: white;
    outline: none;
    font-size: 8px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 1px;
    padding-right: 10px;
    border-radius: 3px;
    box-shadow: 0px 2px 5px rgb(222, 222, 222);
}

.custom-toolbar{
    position: absolute;
    top: 20px;
    right: 0px;
}
.notifications-module .editorClassName{
    border-left: 1px solid #F1F1F1;
    border-right: 1px solid  #F1F1F1;
    border-bottom: 1px solid  #F1F1F1;
    margin-top: -5px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: 10px;
    height: 120px;
    background: #F1F1F1;
}
.notifications-module .toolbarClassName {
    /* border: none; */
    background: #eff9fdbd;
    font-size: 14px !important;
}

.layout-parent-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto;
    width: 96%;
    align-items: center;
}
.layout-type-image{
    height: 350px;
}
.layout-type-select{
    border: 1px solid #78787882;
    border-radius: 10px;
    padding: 18px;
    text-align: center;
    cursor: pointer;
}
.layout-type-select:hover{
    border-color: var(--sidebar_color);
}