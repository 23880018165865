@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  width: 100%;
  height: 100%;
  /* font-family: 'Martel Sans', sans-serif; */
  background-color: #f4f8fc;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  /* overflow-x:hidden;
  overflow-y: auto; */
}
