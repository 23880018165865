.series-details .series-image {
  width: 160px;
  height: 160px;
  border-radius: 10%;
  margin: 14px;
}
.center-loader-screen {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.language_chip {
  padding: 1px 10px 0px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 30px;
}
.modal-schedule-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 10px; */
  padding-left: 10px;
}
.schedule-modal {
  width: 500px !important;
}
.schedule-modal .modal-content {
  width: 500px !important;
}
.modal-schedule-header {
  font-weight: 600;
  font-size: 18px;
}
.modal-schedule-subtext {
  font-weight: 400;
  font-size: 12px;
}
.modal-schedule-content-ul {
  padding: 20px;
}
.modal-schedule-content-li {
  background: rgba(242, 242, 242, 0.77);
  margin: 10px auto;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
}
.modal-schedule-content-li:hover {
  box-shadow: 2px 2px 10px rgb(160, 160, 160, 0.3);
}
.modal-date-time,
.modal-date-title {
  font-size: 16px;
  color: black;
}
.modal-date-date {
  font-size: 16px;
  color: gray;
  margin-right: 10px;
}
.series-details .link-notification-cta {
  background-color: var(--search_chip);
  color: white;
  text-decoration: none;
  padding: 6px 6px;
  font-size: 14px;
  border-radius: 2px;
  border: none !important;
  box-shadow: 2px 4px 10px rgba(184, 184, 184, 0.661);
}
.link-notification-cta:hover {
  opacity: 0.7;
}
.series-details .tags {
  width: 400px;
  border: 1px solid lightsteelblue;
}
.primary {
  display: flex;
  flex-wrap: wrap;
}
.tag-tilte {
  padding: 2px 5px;
  background: #d6d6d6;
  border-radius: 20px;
  font-size: 12px;
  text-transform: capitalize;
}

/* .series-details .tags .primary,
.series-details .tags .secondary {
  display: flex;
  margin: 10px;
  align-items: baseline;
  width: 80%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 6px;
} */

.scroll::-webkit-scrollbar {
  width: 20px;
}

.series-details .tags .primary::-webkit-scrollbar-thumb:hover,
.series-details .tags .secondar::-webkit-scrollbar-thumb:hover {
  box-shadow: none;
}

.series-details .tags .tag {
  white-space: nowrap;
  margin: 4px;
}

.series-details .tags .label {
  margin: 0 10px;
  color: black;
  font-weight: 700;
}

.series-details .none {
  background-color: lightgray;
  padding: 2px;
  font-size: 14px;
  width: 60px;
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
}

.series-details .tags .tag {
  background-color: darkslateblue;
  color: lightgray;
  padding: 4px 6px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}

.series-info {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: white;
  border: 1px solid rgb(206, 206, 206);
  justify-content: space-between;
  border-radius: 4px;
  position: sticky;
  align-items: flex-start;
  z-index: 100;
  height: auto;
  padding-bottom: 10px;
}

.series-action {
  margin: 4px;
  padding: 6px;
  width: 20%;
}

.publish-action {
  text-align: center;
  margin: 18px;
  cursor: pointer;
}
.edit-delete {
  margin-top: 10px !important;
  margin-right: 10px !important;
  float: right;
}
.dropdown-menu.show {
  box-shadow: 2px 2px 20px rgba(128, 128, 128, 0.793);
  z-index: 100;
}
.series-action .edit-delete,
.series-action .add-videos,
.edit,
.delete {
  margin: 8px;
  text-align: center;
  color: green;
}

.experiments button {
  white-space: nowrap;
  margin: 2px;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 2px 4px 10px rgba(184, 184, 184, 0.661);
  border: none;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #d6d6d6;
}

.series-basic-info {
  display: flex;
  flex: 5;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.series-basic-action {
  flex: 1;
}
.series-basic-tags {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  height: 160px;
  /* display: fle; */
}
.performance-cta-series-details {
  font-size: 14px;
  padding: 0px !important;
}
.performance-cta-series-details .icon {
}
.series-basic-tags .experiments {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 10px;
}
.series-basic-tags .primary {
  flex: 1;
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
  grid-gap: 6px;
  margin-bottom: 8px;
}
.series-basic-tags .label {
  font-weight: 600;
}
.flex-date-status .live-status .is_news {
  font-size: 10px;
  padding: 2px 5px !important;
  height: 25px;
  background: green;
  color: white;
}
.flex-date-status .MuiChip-root {
  font-size: 12px;
  padding: 5px 10px !important;
  height: auto !important;
}
.flex-date-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  grid-gap: 40px;
}
.series-created-date {
  font-size: 14px;
  color: gray;
  margin-top: 5px;
  font-weight: 700;
}
.series-id {
  display: flex;
  flex-wrap: wrap;
  justify-self: flex-start;
  align-items: center;
  grid-gap: 20px;
  color: unset;
}
.draft-status {
  font-size: 10px;
  padding: 2px 5px !important;
  height: 20px;
  background: grey;
  color: white;
}
.series-title,
.series-action .date {
  font-weight: 700;
}
.series-status-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.series-category {
  /* font-weight: 700; */
  font-size: 14px;
  border-radius: 40px;
  color: black;
  width: auto;
  padding: 2px 10px 0px 10px;
  background: #d6d6d6;
  /* color: darkgray; */
}
.series-creator {
  display: flex;
  margin-bottom: 5px;
}
.creator-name {
  font-size: 14px;
}

.series-videos .videos {
  border: 1px solid lightgrey;
  background: white;
  margin: 0px;
  border-radius: 4px;
  margin: 10px auto;
  padding: 4px;
}
.series-videos {
  width: 100%;
  height: 53vh;
  overflow-y: scroll;
  margin-top: 10px;
  z-index: -1;
}
.videos-drag-list {
  justify-content: space-between;
  padding: 10px !important;
}
.series-primary-tag {
  background: #d6d6d6;
  border-radius: 5px;
  text-align: center;
  padding: 3px;
  font-size: 12px;
}
.changes-button-group {
  /* width: 230px; */
  box-shadow: none !important;
}

.button-drop-down {
  margin-left: 10px;
}

.changes-button-group button {
  border-radius: 2px !important;
  font-size: 14px !important;
  border: none;
  border-right: 1px solid white;
  outline: none !important;
  background: var(--search_chip) !important;
}

.videos-drag-list-details,
.videos-drag-list-action {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  grid-gap: 10px;
  align-items: center;
}
.videos-drag-list-details-trailer {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 20px;
  border: 1px solid rgb(205, 205, 205);
  justify-content: space-between;
}

.videos-trailer-block {
  display: flex;
  flex-direction: row;
  flex: 1;
  grid-gap: 20px;
  justify-content: flex-start;
  align-items: center;
}
.trailer-player-preview {
  margin: auto;
  width: 240px;
  border-radius: 5px;
}
.videos-trailer-block {
  width: 40px;
}
.play-all-videos-list {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  opacity: 0.7;
  border-radius: 4px;
  width: 90%;
  /* background-color: rgba(0, 0, 0, 0.262); */
  height: 100%;
  border-radius: 10px;
  /* padding: 4px; */
  color: white;
  cursor: pointer;
}
.series-details .play-all-videos {
  position: absolute;
  top: 10px;
  left: 11px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  opacity: 0.7;
  border-radius: 4px;
  margin: 4px;
  width: 80%;
  /* background-color: rgba(0, 0, 0, 0.262); */
  height: 86%;
  border-radius: 10px;
  /* padding: 4px; */
  color: white;
  cursor: pointer;
  /* text-shadow: 2px 2px 10px black; */
}
.play-all-videos-trailer {
  font-size: 40px !important;
  top: 4px !important;
  left: 2px !important;
}
.img-hover-play {
  position: relative;
}

.img-hover-play .series-image {
  aspect-ratio: 10/16;
  width: 8rem;
}
.trailer-play .series-image {
  aspect-ratio: 10/16;
  width: 4rem;
}
.series-primary-action {
  grid-gap: 10px;
}
.video-modal .modal-content {
  width: 320px !important;
  /* height: 800px !important; */
  aspect-ratio: 360 / 800;
  overflow: hidden;
  position: absolute;
}

.video-modal .video-js .vjs-tech {
  overflow: hidden;
  width: 100% !important;
  height: 95% !important ;
  position: absolute;
}

.video-modal .video-js.vjs-fluid {
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 16 / 9;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.video-modal {
  height: 100vh !important;
  width: 80vw !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.video-modal .modal-content .modal-header {
  font-size: small !important;
  padding: 0 !important;
  height: 60px !important;
}
.video-modal .modal-content .modal-header button {
  margin: 0px !important;
}
.video-modal .modal-content .modal-video-title {
  width: 100% !important;
  padding: 4px 0 0 4px !important;
  height: 10px !important;
}

.modal-video-player {
  position: relative;
}

.modal-video-player .icon {
  position: absolute;
  color: white;
  z-index: 10000;
  display: flex;
  justify-content: center;
  top: 120px;
  align-items: center;
  width: 160px;
  padding: 0;
}

.modal-video-player .icon .prev-icon {
  position: absolute;
  padding: 0;
  right: 215px;
  cursor: pointer;
  opacity: 80%;
  color: white;
}

.modal-video-player .icon .next-icon {
  position: absolute;
  right: -50px;
  padding: 0;
  cursor: pointer;
  opacity: 80%;
  color: white;
}

.modal-video-player .icon .prev-icon:hover,
.modal-video-player .icon .next-icon:hover {
  opacity: 1 !important;
  background: black;
}

.video-modal .series-details .modalvideo {
  height: 400px !important;
  width: 300px !important;
}

.series-details .video-js {
  position: relative !important;
  width: 100% !important;
  height: auto !important;
}

.series-details .created {
  margin: 2px !important;
}

.series-details .created .date {
  font-weight: 800 !important;
}

.series-details .videos {
  display: flex;
  align-items: center;
}

.series-details .videos .video-number {
  margin: 0 10px;
  color: grey;
  font-weight: 700;
}

.drag-icon {
  font-size: 20px;
  margin: 10px;
}

.changes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.changes .save,
.changes .cancel {
  margin: 8px;
}

.premium-icon {
  color: darkolivegreen;
  margin-left: 10px;
}

.series-details .activity {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 14px;
  border: 1px solid lightgray;
  padding: 4px;
}

.series-details .activity .title {
  font-size: 1rem !important;
  font-weight: 600;
}

.series-details .activity .type {
  font-weight: 700;
  color: rgb(116, 116, 116);
}

.series-details .activity {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.series-details .video-action button {
  margin-right: 4px;
}

@media only screen and (max-height: 700px) {
  .video-modal .modal-content {
    width: 240px !important;
    aspect-ratio: 360 / 800;
  }
}
.copy-short-link-btn {
  padding: 2px 10px 1px 10px !important;
  font-size: 12px !important;
  border: 2px solid #09e709 !important;
  color: green !important;
  background: #09e70934 !important;
  border-radius: 20px !important;
}

.thumbnail-download-icon {
  position: absolute;
  cursor: pointer;
  padding: 5px;
  width: 25px !important;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.thumbnail-download-icon:hover {
  background-color: rgba(0, 0, 0, 0.9); /* Darken the backdrop on hover */
}

.copy-short-link-btn:hover {
  background: #09e7095b !important;
}
