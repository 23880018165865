.series-experiments{
    /* height: 360px; */
    overflow-y: auto;
    text-align: center;
}
.series-experiments .make-default{
    background-color: rgb(215, 233, 250);
    padding: 2px 8px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 12px;
    text-transform: capitalize !important;
}

.series-experiments .make-default:hover{
    background-color: var(--sidebar_color);
    color: white;
   
}
.series-experiments img {
  border-radius: 10px;
}

.series-experiments .no-experiments{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.series-experiments .experiment-card {
    margin: 10px;
    padding: 12px 0px;
    position: relative;
    box-shadow: 2px 2px 10px rgb(203, 203, 203);
}
.series-experiments .experiment-card .delete-experiment{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1px 12px;
}

.series-experiments .experiment-card .delete-experiment .MuiIconButton-root{
    color: red;
}
.series-experiments .experiment-card .experiment-card-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2px !important;
}

.title-experiment-card{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.experiment-duration-st-end,
.title-variants-input{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.title-variants-input input{
    width: 80%;
}
.images-variants-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.date-string{
    color: grey;
}
.series-experiments .experiment-card .title-var{
    font-size: 14px;
}
.series-experiments .experiment-card .title-exp{
    font-size: 16px;
    font-weight: 500;
}
.series-experiments .experiment-card .changed-values .title{
    font-weight: 600;
    font-size: 18px;
}

.series-experiments .experiment-card .experiment-duration .start,
.series-experiments .experiment-card .experiment-duration .end{
    font-weight: 600;
    font-size: 14px;
}

.series-experiments .experiment-card .experiment-duration .upcoming{
    background-color: rgb(218, 216, 109);
    font-size: 12px;
    height: 24px;
    padding: 2px 10px !important;
}

.upcoming {
    background-color: rgb(218, 216, 109);
    font-size: 12px;
    height: 24px;
    padding: 2px 10px !important;
}

.series-experiments .experiment-card .experiment-duration .expired{
    background-color: rgb(232, 82, 82);
    color: rgb(239, 234, 201);
    font-size: 12px;
    height: 24px;
    padding: 2px 10px !important;
}

.series-experiments .experiment-card .experiment-duration .ongoing{
    background-color: rgb(47, 180, 71);
    color: rgb(239, 234, 201);
    font-size: 12px;
    height: 24px;
    padding: 2px 10px !important;
}

.series-experiments .experiment-card .experiment-duration .deleted{
    background-color: red;
    color: rgb(239, 234, 201);
    font-size: 12px;
    height: 24px;
    padding: 2px 10px !important;
    
}

