.login-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(168, 167, 167, 0.219);
}

.login-form .seekho-image {
  width: 60px;
  padding: 5px;
  margin-bottom: 10px;
}

.login-form .title {
  font-size: 32px;
  margin-top: 30px;
}

.login-form .mobile-number {
  width: 200px;
  display: inline;
  text-align: center;
}
.retry-otp-section{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  margin-top: 10px;
  font-size: 14px;
}

.login-form .inputs{
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form .button{
    background-color: #09053f;
    color: #c8d4e0;
    border: none;
    margin: 10px;
    
}

#recaptcha-container
{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 600) {
    .login-form {
        border:0px solid green
      }
      
  }