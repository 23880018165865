.la-ball-clip-rotate,
.la-ball-clip-rotate > div {
  position: relative;
  box-sizing: border-box;
}
.la-ball-clip-rotate {
  display: block;
  font-size: 0;
  color: #2B689C;
}

.la-ball-clip-rotate > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-ball-clip-rotate {
  width: 32px;
  height: 32px;
}
.la-ball-clip-rotate > div {
  width: 32px;
  height: 32px;
  background: transparent;
  border-width: 2px;
  border-bottom-color: transparent;
  border-radius: 100%;
  animation: ball-clip-rotate 0.75s linear infinite;
}

@keyframes ball-clip-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


