.center {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.btn-extract-thumbnail {
  border: 1px solid gray !important;
}

.upload-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.search-select {
  display: flex;
  flex-direction: column;
  width: 240px;
  margin: 1px 0;
}

.search-select select {
  border: 0;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown input {
  width: 100%;
  border: 1px solid lightgray;
  padding: 6px;
  border-radius: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown .dropdown-content div {
  color: black;
  padding: 4px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.progress-bar-container {
  width: 100%;
  height: 6px;
  background-color: #ddd;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.progress-bar {
  height: 100%;
  background-color: #32a620;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
}
