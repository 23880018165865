.video-js {
    position: absolute;
    z-index: 0;
}
.video-js .vjs-time-control {
    display: block !important;
    padding: 0px !important;
}
.video-js .vjs-remaining-time {
    display: none !important;
}

.vjs-picture-in-picture-control{
    display: none !important;
}

.vjs-progress-control{
    position: absolute !important;
    bottom: 30px;
    right: 2px;
    width: 100% !important;
    height: 10px !important;
    opacity: 1;
    z-index: 0;
}

.vjs-fullscreen-control{
    position: absolute !important;
    right: 0px;
}

.next-icon-fs{
    position: absolute;
    right: 0px;
    float: right;
    cursor: pointer;
}
.prev-icon-fs{
    position: absolute;
    left: 0px;
    float: left;
    cursor: pointer;
}

.icon-fullscreen{
    z-index: 100000 !important;
    margin-top: 40px;
    height: 20px;
    width: 90%;
    position: relative;
    grid-gap: 1000;
}

/* Style the playback speed button */
.vjs-playback-rate {
    position: absolute !important;
    right: 30px;
    color: #fff;
    font-size: 8px;
    top: 2.7px;
  }
  
  .vjs-playback-rate .vjs-menu-content {
      display: none !important;
  }
  