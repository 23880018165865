.image-editor {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 90%;
  height: auto;
  overflow: hidden;
  margin-bottom: 80px;
}
.modal-open .modal.image-editor-modal{
  overflow-y: hidden;
}
.editor-options-section{
  flex: 1;
  height: 100%;
  margin-top: 20px;
}
.editor-image-section{
  flex: 8;
  height: 100%;
}
.button-cta-editor{
  background: var(--sidebar_color) !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  padding: 2px 10px !important;
  border-radius: 70px !important;
  margin-left: 10px !important;
}

.ImageEditor-controls-15{
  border: 1px solid rgb(196, 196, 196);
  border-radius: 4px;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.image-editor .preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background: rgba(229, 229, 229, 0.662);
  height: 100%;
  max-height: 100%;
  margin: auto;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
}

.image-editor .preview .preview-image {
  width: 332px;
  height: 470px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
}

.image-editor .download-div {
  width: 332px;
  height: 470px;
  position: relative;
  margin: auto !important;
}
.main-image,
.preview-image-editor{
  height: 100%;
}
.preview-image-editor .main-image img{
  height: 100% !important;
}
.image-editor .gradient {
  width: 332px;
  height: 222px;
  position: absolute;
  bottom: 0px;
}
.download-image .series-details{

}
.image-editor .series-details {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.image-editor .series-details .series-title {
  font-size: 38px;
  color: #fff;
  font-weight: 700;
  padding: 16px 48px;
  position: absolute;
  bottom: 10px;
  margin-bottom: 24px;
  line-height: 46px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: 'Teko', sans-serif;
}

.image-editor .series-details .creator-details {
  color: #ffffff;
  position: absolute;
  bottom:  0px;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  padding: 8px 12px;

  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

}

.image-editor .series-details .creator-details span{
  margin: 16px;
  padding: 8px 16px;
}

.image-editor .logo-image {
  top: 50px;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 2;
}

.image-editor .download-preview {
  position: relative;
  left: 200vw;
}

.image-editor .download-preview .download-image {
  width: 664px;
  height: 940px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  z-index: -1;
}

.image-editor .download-preview .download-image .download-div {
  width: 664px;
  height: 940px;
  position: relative;
}

.image-editor .download-preview .download-image .gradient {
  width: 664px;
  height: 444px;
  position: absolute;
  bottom: 0px;
}

.image-editor .download-preview .download-image .series-details {
  position: relative;
  z-index: 3;
  bottom: 60px;
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-editor .download-preview .download-image .series-details .series-title {
  font-size: 18px;
  color: lightgray;
  font-weight: 600;
}

.image-editor
  .download-preview
  .download-image
  .series-details
  .creator-details {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.image-editor .download-preview .download-image .logo-image {
  top: 100px;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 2;
}

.image-editor .options .actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10%;
}
.input-hide-editor{
  /* margin-left: -28px; */
  opacity: 0;
  width: 58px;
  height: 58px;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer !important;
}
.icon-background-editor .MuiSvgIcon-root {
  cursor: pointer !important;
}
.icon-background-editor{
  border: 1px solid rgb(223, 223, 223) !important;
  /* background: rgb(223, 223, 223); */
  border-radius: 10px;
  width: 60px !important;
  height: 60px !important;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer !important;
  margin-bottom: 10px;
}
.icon-background-editor-title{
  font-size: 10px;
  color: grey;
}
.editor-image-section-top-action{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px;
}
.editor-image-section-image-space{
  border: 1px solid;
  /* height: 430px; */
  height: 470px;
  max-height: auto;
  width: 96%;
  border-radius: 10px;
  margin: auto;
  border: 1px solid rgb(226, 226, 226);
}
.reactEasyCrop_Container{
  /* width: 80%; */
  /* height: 400px; */
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 10px;
}
.editor-image-section-image-slider{
  display: flex;
  width: 96%;
}
.reactEasyCrop_CropArea{
  color: rgb(185 177 177 / 90%) !important;
}
.input-color-hide{
  border: 1px solid red !important;
  width: 100%;
  height: 60px;
  position: absolute;
  opacity: 0;
  cursor: pointer !important;
}