.HTMLContent a {
  @apply text-blue-500;
}

.HTMLContent img {
  @apply max-w-full max-h-[480px] mx-auto object-contain;
}

.HTMLContent iframe {
  @apply w-[100%] !h-96 mx-auto;
}

.HTMLContent u {
  @apply underline;
}

.HTMLContent h1 {
  @apply text-[24px]  lg:text-[34px] lg:!leading-[44px];
}

.HTMLContent h2 {
  @apply text-[18px]  lg:text-[30px] lg:!leading-[39px];
}

.HTMLContent h3 {
  @apply text-[18px]  lg:text-[24px] lg:!leading-[30px];
}

.HTMLContent code {
  @apply text-[14px];
}

.HTMLContent ol,
.HTMLContent ul {
  list-style-position: inside;
  margin-right: 10px !important;
}

.HTMLContent ol li {
  list-style-type: decimal;
  margin-top: 10px;
  margin-left: 30px !important;
}

.HTMLContent ul li {
  list-style-type: disc;
  margin-left: 30px !important;
}

.HTMLContent blockquote {
  @apply border-l-4 border-gray-300 pl-4 italic text-gray-600;
}

.HTMLContent .ql-align-center {
  @apply text-center;
}
