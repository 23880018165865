.center-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-layout-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: row;
  overflow: hidden;
  /* grid-gap: 20px; */
  /* justify-content: flex-end; */
  align-items: flex-start;
}
.navbar-wrapper {
  width: 300px !important;
  height: 100%;
}
.content-wrapper {
  width: 82%;
}

.center-btn button {
  margin: 10px 24px;
  background-color: #09053f;
  color: #c8d4e0;
  border: none;
  margin: 10px;
  padding: 10px;
  border-radius: 20%;
}

.creator-name {
  text-decoration: none;
  color: black;
  text-decoration: double;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.App {
  /* font-family: 'Martel Sans', sans-serif; */
  background-color: #f4f8fc;
}

.spacer {
  flex: 1;
}

.content {
  padding: 5px;
}
.modal-content button.close {
  color: white !important;
  opacity: 1 !important;
}

.sidebar-wrapper {
  width: 100%;
  height: 100%;
}
.sidebar {
  width: 100%;
  border-right: 2px solid rgb(214, 214, 214);
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .navbar-wrapper {
    width: 230px !important;
    height: 100%;
  }
  .content-wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .app-layout-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .SW-splash-screen-left {
    display: none;
  }
  .card-panel .MuiBox-root {
    zoom: 0.7 !important;
  }
}
.MuiChip-label.MuiChip-labelMedium {
  padding: 0px !important;
}
.MuiButtonBase-root.MuiPaginationItem-root {
  height: 25px !important;
  min-width: 25px !important;
}
.navbar-wrapper {
  position: relative !important;
  height: 100%;
}
.icon-collapseable {
  position: absolute !important;
  top: 10px;
  box-shadow: 2px 2px 10px rgba(204, 203, 203, 0.728);
  background: rgb(240, 240, 240) !important;
  right: -10px;
  width: 30px;
  height: 30px;
}

.modal-video-player .icon .prev-icon:hover,
.modal-video-player .icon .next-icon:hover {
  opacity: 1;
  background: black;
}
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #e5e5e5c0;
  border-radius: 50px;
}
div::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  border-radius: 50px;
  background-color: #cacaca;
}

div::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border: 0px solid #e5e5e54d;
  border-radius: 50px;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-deletable {
  grid-gap: 10px;
  text-transform: capitalize !important;
}

/* Hide scrollbars in Webkit browsers (Chrome, Safari) */
#HideScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Hide scrollbars in Firefox */
#HideScroll {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbars in IE and Edge */
#HideScroll {
  -ms-overflow-style: none; /* IE and Edge */
}

/* For older versions of Edge */
#HideScroll::-ms-scrollbar {
  display: none; /* Older Edge versions */
}

/* Additional rules to ensure compatibility and smooth scrolling */
#HideScroll {
  overflow: -moz-scrollbars-none; /* Older Firefox versions */
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

.dropdown-action-common {
  background-color: transparent !important;
  color: transparent !important;
  box-shadow: none;
  width: 40px !important;
  border: 1px solid transparent !important;
  font-size: 0.8rem !important;
  z-index: 100;
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

#ThinGrayScrollBar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgb(181, 181, 181) #f1f1f1; /* For Firefox */
}

#ThinGrayScrollBar::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  border-radius: 50%;
}

#ThinGrayScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 50px;
}

#ThinGrayScrollBar::-webkit-scrollbar-thumb {
  background: rgb(181, 181, 181);
  border-radius: 5px;
}

#ThinGrayScrollBar::-webkit-scrollbar-thumb:hover {
  background: rgb(223, 223, 223);
}

.cta-button-create {
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  width: auto;
  max-width: 60px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
  height: 60px;
  color: white !important;
  display: flex;
  background: var(--sidebar_color);
  box-shadow: 1px 1px 40px var(--search_chip);
}

.cta-button-create span {
  white-space: nowrap;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 16px;
  text-transform: capitalize;
}

.icon-plus-cta {
  margin-left: 13px;
}

.cta-button-create:hover {
  max-width: 300px;
}

.series-avatar {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 10%;
  margin-right: 16px;
}

.table-rowserie-thumbnail {
  min-width: 104px;
  min-height: 148px;
  aspect-ratio: 9/16;
  border-radius: 10px;
  border: 1px solid rgba(164, 164, 164, 0.763);
}

.action-button-filter {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  justify-content: flex-end;
}

.box-filter-drawer {
  overflow-y: scroll;
  height: 92vh;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.modal-video-title {
  text-align: left;
  font-weight: bold;
  padding: 0%;
}

.video-modal {
  height: 50%;
  width: -moz-fit-content;
  width: fit-content;
}

.chip-hardcode {
  text-transform: capitalize !important;
}

.page-header-series {
  display: block;
  align-items: center;
  width: 100%;
  margin-top: 1px;
}

.action-button-group {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10px;
  flex-wrap: wrap;
}

.custom-filter-button {
  background: transparent !important;
  color: black !important;
  text-transform: capitalize !important;
  border: 1px solid rgb(206, 206, 206) !important;
  box-shadow: none !important;
  grid-gap: 10px;
}

.create-button {
  border-radius: 5px !important;
  background: var(--sidebar_color) !important;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}

.card-panel .MuiBox-root {
  padding: 0px !important;
  margin-top: 4px;
}

.MuiTable-root.MuiTable-stickyHeader {
  background-color: transparent !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.MuiTableCell-sizeMedium {
  font-size: 15px !important;
  font-weight: 500;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  border: 1px solid var(--search_chip) !important;
  border-bottom: 0px solid var(--search_chip) !important;
  border-top-left-radius: 5px;
  background-color: #b2adff64;
  border-top-right-radius: 5px;
}

.shadow-table {
  width: 100%;
  height: 100% !important;
  position: relative;
  box-shadow: 2px 2px 20px rgb(196, 196, 196) !important;
}

.table-series thead {
  border-bottom: 1px solid rgb(196, 196, 196) !important;
}

.table-series thead th {
  font-size: 12px !important;
  background-color: white !important;
}

/* TABS CSS */
.MuiButtonBase-root.MuiTab-root {
  border: 2px solid rgba(255, 255, 255, 0) !important;
  border-bottom: 0px solid white !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 13px;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: var(--search_chip);
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  color: grey;
}

.search-paper {
  background-color: transparent !important;
}

.upload {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}

.dropdown-action {
  background-color: transparent !important;
  color: transparent !important;
  box-shadow: none;
  width: 40px !important;
  border: 1px solid transparent !important;
  font-size: 0.8rem !important;
  z-index: 100;
}

.custom-drawer-list-series {
  width: 100%;
  margin-left: 0px !important;
}

thead th {
  position: sticky !important;
  top: 0 !important;
}

td {
  border: none !important;
  font-size: 1rem;
  vertical-align: middle !important;
}

.live-btn {
  background-color: #09053f;
  border-radius: 10%;
  padding: 5px 10px;
  width: 100px;
  color: #bdcad8;
  border: none;
  border: 0;
}

.modal-video-title {
  text-align: left;
  font-weight: bold;
  padding: 0%;
}

.video-modal {
  height: 50%;
  width: -moz-fit-content;
  width: fit-content;
}

.filter {
  border-radius: 4px;
}

.form-checkbox {
  background-color: black !important;
}

.filter {
  margin-left: 2rem;
  padding: 1rem;
}

.review-rejected {
  color: rgb(243, 111, 111);
}

.filter-drawer {
  padding-left: 10px;
}

.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.MuiRadio-root {
  zoom: 0.8;
  color: green;
}

.filters-series {
  display: flex;
  margin-bottom: 20px;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: flex-start;
}

.form-checkbox-align .form-check-label {
  margin-top: 2px;
  font-size: 12px;
}

.apply-button {
  width: 100px;
  top: 70%;
}

.filter-header {
  font-size: 1rem;
  color: var(--filter-header-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 3px 0;
  padding: 10px 10px;
  text-transform: uppercase;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-align: left;
}

.form-col {
  font-family: "Roboto", "Arial", sans-serif;
  color: var(--filter-header-color);
  font-size: 12px;
  padding: 15px 0 0;
}

.rejection-logs {
  color: var(--sidebar_color) !important;
  font-size: 1.2rem !important;
}

.rejection-logs.w-color {
  color: white !important;
  font-size: 1.2rem !important;
}

.action-menu {
  background-color: white;
  box-shadow: 2px 2px 40px grey;
}

.action-menu-item {
  padding: 10px 20px !important;
}

.action-menu-item:hover {
  background-color: #8277d43a !important;
}

/* Hide the arrows if input type number in most browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide the arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
