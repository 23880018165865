.content-manager-modal-btn
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-manager-modal{
  padding: 20px;
}
.content-manager-modal select{
  border: 1px solid rgb(203, 203, 203);
  border-radius: 5px;
}
.ptag-modal-header{
  font-size: 16px;
  margin-bottom: 10px;
}
.content-manager-modal .managers{
  padding: 0px;
  margin-left: 0px;
  width: 70%;
  height: 40px;
}
.content-manager-modal-btn{
  /* border: 1px solid; */
  padding: 20px;
  grid-gap: 20px;
}
.content-manager-modal-btn .change-btn{
  background-color: var(--search_chip);
  color: white;
  border: none !important;
}

.content-manager-modal-btn .close-btn{
  background-color: #c8d4e000;
  color: #09053f;
}
