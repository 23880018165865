.experiments-list .upcoming {
  background-color: rgb(216, 213, 52);
  color: rgb(67, 67, 67);
  font-weight: 400;
  font-size: 12px;
  height: 24px;
  padding: 2px 10px !important;
}

.filters-list-experiment {
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}

.experiments-list .card-header {
  margin: 10px 0px;
}

.filters-list-experiment .filter-box {
  display: flex;
  font-size: medium;
}
.title-experiment-row {
  width: 300px;
  min-width: 200px;
  width: 300px;
}

.experiments-list .expired {
  background-color: rgb(232, 82, 82);
  color: rgb(255, 255, 255);
  width: auto;
  font-weight: 400;
  font-size: 12px;
  height: 24px;
  padding: 2px 10px !important;
}

.experiments-list .ongoing {
  background-color: rgb(47, 180, 71);
  color: rgb(255, 255, 255);
  width: auto;
  font-weight: 400;
  font-size: 12px;
  height: 24px;
  padding: 2px 10px !important;
}

.experiments-list .deleted {
  background-color: rgb(117, 117, 117);
  color: rgb(255, 255, 255);
  width: auto;
  font-weight: 400;
  border-radius: 15px;
  font-size: 12px;
  height: 24px;
  padding: 4px 10px 5px 10px !important;
}

.experiments-list .filters-experiments {
  display: flex;
  justify-content: space-between;
}

.experiments-list .loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experiments-list .apply-button {
  color: azure;
}

.custom-searchbox {
  border: 1px solid rgb(206, 206, 206);
  border-radius: 5px;
}

.serieslist-search-col {
  flex: 2;
  margin-left: -1px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: white;
}
