/* .login-page {
  padding: 20px;
  width: 100%;
  text-align: center;
  display: flex;
  display: inline;
  justify-content: center;
}

.login {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .mobile {
    display: none;
  }

  .login {
    margin-top: 0;
  }
}

.google-play{
    margin:20px;
    border: 1px solid rgba(168, 167, 167, 0.219);
}
.google-play img{
    width: 200px;
} */

:root {
  --sidebar_original_color: #09053f;
  --sidebar_color: #534f8f ;
  --search_chip: #8277d4 ;
  --filter-header-color:#464646;
  --font-family-used: "Roboto","Arial",sans-serif;
  /* #5f59b4 ;
  --white: #ffffff; */
}

.SW-wrapper-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}
.SW-splash-block {
  width: 100%;
  height: 100%;
}
.SW-splash-screen-left {
  flex: 3;
  border: 1px solid black;
}
.SW-google-play{
    float: right;
}
.SW-splash-block-image {
  width: 100%;
  height: 100%;
  margin: auto;
  min-width: 300px;
}
.SW-splash-screen-form {
  flex: 5;
  height: 100vh;
  width: 100%;
}

.SW-login-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 13% auto;
  height: 500px;
  border: 1px solid #c5c5fcbf;
  padding: 20px;
  box-shadow: 2px 2px 600px #c4bcff64;
  border-radius: 10px;
  background: #c4bcff28;

}
.SW-title-logo-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SW-google-play-image{
    width: 180px;
    height: 70px;
}
.SW-seekho-image{
    /* width: 80px; */
    height: 70px;
    margin-top: 20px;
}
.login-error-state{
  color: red;
  font-size: 12px;
  margin: 5px auto;
}
.SW-seekho-title{
    font-size: 34px;
    font-weight: 700;
    text-orientation: sideways;
    /* font-style: oblique; */
}
.SW-login-form-wrapper{
  /* margin-top: -40px; */
}
.SW-mobile-number-field{
    height: 40px;
    width: 90%;
    border-radius: 6px;
    border: 1px solid #c5c5fcbf;
    outline: none;
    font-size: 16px;
    padding-left: 10px;
}
.phonecode-wrapper{
  height: 40px;
  width: 90%;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 16px;
  padding-left: 10px;
  outline: none;
}

.mobile-input{
  border: none; 
  background-color: transparent;
  width: 100%;
  padding: 5px;
}

.mobile-input:focus{
  outline: none !important;
}

.otp-input {
  border: 1px solid #8277d4;
  width: 100%;
  border-radius: 6px;
  height: 40px;
  padding-left: 10px;
}

.otp-input:focus{
  outline: none;
}

.phonecode{
  grid-gap: 10px;
  display: flex;
  flex-direction: row !important;
  justify-items: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  /* border: 1px solid #c5c5fcbf; */
  outline: none;
  font-size: 16px;
  background: white;
  padding-left: 10px;
}
.SW-text-muted{
    font-size: 10px;
    color: grey;
    margin-top: 0px;
}
.SW-form-label{
    margin-left: 1px;
    float: left;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: grey;

}
.SW-login-inputcontrol{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    align-items: flex-start;
    justify-content: center;
    margin-top: 80px;
}
.SW-CTA-button{
    width: 60%;
    margin: auto;
    margin-top: 40px;
    color: white;
    border: none !important;
    background: var(--search_chip) !important;
    box-shadow: 2px 2px 2px rgb(153, 153, 248);
}

.SW-CTA-button:hover{
    opacity: 0.8;
}