.image-editor-modal {
  max-width: 100vw;
  min-width: 100vw;
  width: 1000px;
  margin: auto !important;
  display: flex;
  height: 100vh !important;
  overflow: hidden;
}

.series-title-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.series-title-dropdown {
  width: 80%;
  position: relative;
  border-radius: 40px;
}

.series-title-dropdown .dropdown {
  position: absolute;
  top: 40px;
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 100%;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
}

.series-title-dropdown .dropdown li {
  background-color: #ffffff;
  border-top: 1px solid rgba(0,0,0,.107);
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.series-title-dropdown .dropdown .show-more {
  padding: 10px;
  font-size: 12px;
  text-align: right;
  border: 1px solid lightgray;
  background-color: rgb(237, 237, 237);
  color: rgb(14, 95, 247);
}

.series-title-dropdown .dropdown .show-more span {
  cursor: pointer;
}

.series-title-dropdown .dropdown li:hover {
  background-color: #c0c0c0;
}

.verify {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 10px;

  margin: 0 8px;
  border: 1px solid rgb(129, 129, 129);
}

.image-editor-modal .modal-dialog {
  max-width: 98vw;
  text-align: center;
  display: flex;
  min-height: 100vh;
  padding: 0;
  margin: auto;
  /* height: 100%; */
}

.create-series .cta {
  display: flex;
  width: 100%;
  padding: 0px;
  height: auto;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  grid-gap: 10px;
}
.create-series .cta.form-group div{
  flex: 1 !important;
}
.create-series .cta div button {
  width: 100%;
  padding: 0px;
  height: 30px;
}
.form-control-file{
  height: 0px;
}

.create-series .cta .title-icon input {
  visibility: hidden;
  width: 0px;
}

.create-series .image-preview {
  margin: 1px 14px 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.create-series .image-preview label {
  font-size: 18px;
  font-weight: 600;
  border: 1px solid lightgray;
}

.create-series .image-preview .icon {
  cursor: pointer;
}

.create-series .image-cta {
  flex-direction: column;
}
