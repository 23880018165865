:root {
    --sidebar_original_color: #09053f;
    --sidebar_color: #534f8f ;
    --sidebar_color_faded: #00000042 ;
    --search_chip: #8277d4 ;
    --filter-header-color:#464646;
    --sidebar_color_alternate:#8277d4;
    --font-family-used: "Roboto","Arial",sans-serif;
    /* #5f59b4 ;
    --white: #ffffff; */
}
.add-series-videos .video-details{
    border: 1px solid lightgray ;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 10px auto;
}

.add-series-videos{
    height: 500px;
    width: 100%;
    margin: auto;
    overflow-y: scroll;
}

.add-series-videos .add-btn{
    margin: 10px auto;
    width: auto;
    border-color: var(--sidebar_color);
    color: var(--sidebar_color);
    /* background: var(--sidebar_color); */
}
.flex-center{
    text-align: center;
}