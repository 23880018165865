#QuillEditor u {
  text-decoration: underline;
}

.ql-tooltip {
  margin-left: 200px;
}

#QuillEditor img {
  @apply max-w-full max-h-[480px] mx-auto object-contain;
}

#QuillEditor iframe {
  @apply w-[90%] !h-96 mx-auto;
}

#QuillEditor a {
  @apply text-blue-500;
}

#QuillEditor u {
  @apply underline;
}

#QuillEditor h1 {
  @apply text-[24px]  lg:text-[34px] lg:!leading-[44px];
}

#QuillEditor h2 {
  @apply text-[18px]  lg:text-[30px] lg:!leading-[39px];
}

#QuillEditor h3 {
  @apply text-[18px]  lg:text-[24px] lg:!leading-[30px];
}

/* Styles for the Quill editor toolbar */
#QuillEditor .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  padding: 10px 0;
}

#QuillEditor .ql-container {
  @apply h-[calc(100vh-200px)];
}

/* Quill editor container with proper scrolling */
#QuillEditor .ql-container {
  overflow-y: auto;
  scroll-behavior: smooth;
}

/* Custom Scrollbar Styling for WebKit Browsers (Chrome, Safari, Edge): */
#QuillEditor ::-webkit-scrollbar {
  width: 6px;
}

#QuillEditor ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#QuillEditor ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

#QuillEditor ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom Scrollbar Styling for Firefox: */
#QuillEditor {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

#QuillEditor::-webkit-scrollbar {
  width: 12px;
}

#QuillEditor::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#QuillEditor::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

#QuillEditor::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom Scrollbar Styling for Internet Explorer/Edge Legacy: */
#QuillEditor {
  -ms-overflow-style: scrollbar;
}
