.experiments-list {
  padding: 0px;
}
.button-custom {
  background: var(--sidebar_color);
  margin-right: 10px;
  border: none;
}
.dfs-series-ids {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.button-custom-cancel {
  border: 1px solid var(--sidebar_color) !important;
  margin-right: 10px;
  background: none;
  color: var(--sidebar_color);
}
.button-custom-cancel:hover {
  border-color: black;
  color: black;
  background: none !important;
}
.flex-bottom-code {
  align-items: end;
}
.track-in_active {
  background: rgb(189, 189, 189) !important;
  padding: 1px 8px !important;
  height: 20px !important;
  font-size: 10px !important;
}
.track-active {
  background: rgb(8, 153, 8) !important;
  padding: 1px 8px !important;
  height: 20px !important;
  color: white !important;
  font-size: 10px !important;
}
.track-default {
  background: blue !important;
  padding: 1px 8px !important;
  height: 20px !important;
  color: white !important;
  font-size: 10px !important;
}

.button-custom:hover {
  border-color: transparent;
  color: white;
  background: var(--sidebar_color) !important;
  opacity: 0.9;
}
.button-custom.btn-primary.disabled,
.button-custom.btn-primary:disabled {
  background: grey;
}
.button-custom.btn-primary.disabled:hover {
  background: grey !important;
}
.form-label {
  position: relative;
  width: auto;
}
.required-label {
  font-size: 14px;
  color: red;
  position: absolute;
  right: -10px;
  font-weight: 800;
}
.flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.videos-drag-list-details-campaign {
  border: 1px solid rgba(128, 128, 128, 0.494);
  margin-top: 3px;
  border-radius: 4px;
}
.drag-list-track-custom {
  width: 100% !important;
  padding: 0px !important;
  box-shadow: none !important;
  margin-top: 0px !important;
}
.MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0px !important;
  padding-right: 40px !important;
}
.MuiAccordionDetails-root {
  padding: 0px !important;
  padding-right: 40px !important;
}
.stepper-form-progress {
  padding: 0px;
  padding-top: 0px;
  border: 1px solid none;
  width: 100%;
  display: flex !important;
  position: relative;
  height: 100vh;
}
.stepper-form-progress-edit {
  padding: 20px;
  padding-top: 0px;
  padding-right: 0px;
  border: 1px solid none;
  width: 100%;
  position: relative;
  height: 88vh;
}
.status-active {
  background: green;
}
.button-edit-cts {
  position: absolute !important;
  background: var(--sidebar_color) !important;
  top: 0px;
  right: 10px;
}
.full-layout-scroll-tabs {
  padding: 0px !important;
  margin-top: 0px;
  display: flex !important;
  flex-direction: column;
  flex: 7;
  position: relative;
  /* overflow-y: scroll; */
  /* height: 85%; */
}
.full-layout-scroll-tabs-action-buttons {
  position: absolute;
  /* top: 0px; */
  /* width: 100%; */
  bottom: 115px;
  right: 0px;
}
.layout-scroll-button-bottom {
  /* border: 1px solid red; */
  /* position: absolute; */
  bottom: 10px;
  width: 100%;
  grid-gap: 20px;
  display: flex;
  border-radius: 4px;
  padding-top: 10px;
}
.layout-scroll-button-bottom-edit {
  position: absolute;
  bottom: -30px;
  width: 100%;
  grid-gap: 20px;
  display: flex;
  border-radius: 4px;
  padding-top: 10px;
}
.track-create-box {
  width: 100%;
}
.shadow-table-form-edit {
  width: 100%;
  overflow-y: scroll !important;
  position: relative;
  height: 80vh;
  box-shadow: 2px 2px 20px rgba(196, 196, 196, 0.3) !important;
}
.select-option-custom {
  width: 100%;
  height: 35px;
  border-radius: 3px;
  border-color: rgba(196, 196, 196, 0.8);
}
.justify-center-align-center {
  justify-content: space-between;
  align-items: center;
}
.flex-view-list-track {
  justify-content: space-between;
  align-items: start;
  grid-gap: 0px;
}
.detailed-view-track {
  flex: 8;
  position: relative;
}
.list-view-track {
  flex: 2;
}
.full-layout-scroll-enable-edit {
  height: 95vh !important;
  overflow-y: hidden !important;
  position: relative;
}

.ul-track-list {
  width: 100%;
  max-width: 340px;
  background: white;
  overflow-y: scroll !important;
  position: relative;
  height: 86vh;
  border-radius: 4px;
  overflow-x: hidden;
  padding: 10px;
  box-shadow: 2px 2px 20px rgba(196, 196, 196, 0.3) !important;
}
.li-track-list {
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.label-track-header {
  font-size: 16px;
  color: var(--sidebar_color);
}
.drag-flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.active-dot {
  background: green;
  width: 6px;
  height: 6px;
  border-radius: 10px;
}
.text-align-center-track {
  text-align: center;
  display: flex;
  font-weight: bolder;
  font-size: 22px;
  justify-content: center;
}
.label-track {
  font-size: 16px;
  text-decoration: none !important;
  width: inherit;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 3px;
  border: 0.6px solid rgba(128, 128, 128, 0.2);
}
.label-track:hover .li-track-list {
  background: rgba(128, 128, 128, 0.2);
  border-radius: 4px;
}
.chip-status {
  width: 100px !important;
  margin-left: 20px;
}
.img-nofound-wrapper-center-wrapper {
  width: 100%;
  background: white;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgb(215, 215, 215);
}
.img-nofound-wrapper-center {
  width: 100%;
}

.stepper-form-progress .MuiButtonBase-root.MuiTab-root.Mui-selected {
  border: 1px solid var(--search_chip) !important;
  border-bottom: 1px solid var(--search_chip) !important;
  border-right: 0px solid var(--search_chip) !important;
  background-color: #b2adff64;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 5px;
}
.stepper-form-progress .MuiButtonBase-root.MuiTab-root {
  align-items: flex-start;
}
.shadow-table-form {
  width: 100%;
  overflow-y: scroll !important;
  position: relative;
  flex: 1;
  margin: 0px !important;
  box-shadow: 2px 2px 20px rgba(196, 196, 196, 0.3) !important;
}
.stepper-form-progress .card-panel.creator-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 86vh;
  align-items: flex-start;
}
.stepper-form-progress.create-track .card-panel.creator-table {
  height: 92vh;
}
.full-layout-scroll-enable.track-list {
  height: 95vh !important;
}
.full-layout-scroll-enable.track-list .creator-table {
  height: fit-content !important;
}
.chip-for-added-category {
  padding: 4px 10px !important;
  margin-right: 10px !important;
  background: white !important;
}
.required-label-info {
  color: blue;
  font-size: 12px;
}
.drag-custom-accordion {
  border-radius: 5px !important;
  border: 1px solid rgba(128, 128, 128, 0.494) !important;
  margin-top: 10px !important;
  padding: 5px 0px 5px 40px !important;
  box-shadow: none !important;
}

.add_exp_upload_csv_container {
  display: flex;
}

.input-file-csv {
  position: absolute;
  left: 5px;
  width: 120px;
  top: 8px;
  opacity: 0%;
  cursor: pointer;
}
